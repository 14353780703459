<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <!--<el-form-item :label="$t('searchModule.Merchant_Name')">-->
          <!--<el-select v-model.trim="formInline.tenantId"-->
          <!--filterable-->
          <!--size="15">-->
          <!--<el-option label="全部"-->
          <!--value=""></el-option>-->
          <!--<el-option :label="value.tenantName"-->
          <!--:value="value.tenantId"-->
          <!--:key="value.tenantId"-->
          <!--v-for="value in roleList"></el-option>-->
          <!--</el-select>-->
          <!--</el-form-item>-->
          <!--<el-form-item label="日期：">-->
          <!--<el-date-picker v-model="date"-->
          <!--type="date"-->
          <!--placeholddaoer="选择日期"-->
          <!--@change='showLog'> </el-date-picker>-->
          <!--</el-form-item>-->
          <!--<el-form-item :label="$t('searchModule.date')"-->
          <!--prop="dateType"-->
          <!--&gt;-->
          <!--<el-select v-model.trim="formInline.dateType"-->
          <!--filterable-->
          <!--size="15"-->
          <!--style="width:100px;"-->
          <!--&gt;-->
          <!--<el-option label="日"-->
          <!--value="day">日</el-option>-->
          <!--<el-option label="月"-->
          <!--value="month">月</el-option>-->
          <!--<el-option label="年" value="year">年</el-option>-->
          <!--&lt;!&ndash;<el-option label="自定义" value="custom">自定义</el-option>&ndash;&gt;-->
          <!--</el-select>-->
          <!--</el-form-item>-->
          <!--<el-date-picker v-model="startTime"-->
          <!--:picker-options="pickerOptions"-->
          <!--type="date"-->
          <!--placeholder="选择日期"-->
          <!--v-show="formInline.dateType == 'day'"></el-date-picker>-->
          <!--<span v-show="formInline.dateType == 'day'">至</span>-->
          <!--<el-date-picker v-model="endTime"-->
          <!--:picker-options="pickerOptions"-->
          <!--type="date"-->
          <!--placeholder="选择日期"-->
          <!--v-show="formInline.dateType == 'day'"></el-date-picker>-->
          <!--<el-date-picker v-model="startTime"-->
          <!--:picker-options="pickerOptions"-->
          <!--type="month"-->
          <!--placeholder="选择月"-->
          <!--v-show="formInline.dateType == 'month'"></el-date-picker>-->
          <!--<span v-show="formInline.dateType == 'month'">至</span>-->
          <!--<el-date-picker v-model="endTime"-->
          <!--:picker-options="pickerOptions"-->
          <!--type="month"-->
          <!--placeholder="选择月"-->
          <!--v-show="formInline.dateType == 'month'"></el-date-picker>-->

          <!--<el-date-picker v-model="startTime"-->
          <!--:picker-options="pickerOptions"-->
          <!--type="year"-->
          <!--placeholder="选择年"-->
          <!--v-show="formInline.dateType == 'year'"></el-date-picker>-->
          <!--<span v-show="formInline.dateType == 'year'">至</span>-->
          <!--<el-date-picker v-model="endTime"-->
          <!--:picker-options="pickerOptions"-->
          <!--type="year"-->
          <!--placeholder="选择年"-->
          <!--v-show="formInline.dateType == 'year'"></el-date-picker>-->
          <el-form-item :label="$t('searchModule.date')"></el-form-item>
          <el-form-item>
            <el-date-picker
              unlink-panels
              v-show="formInline.dateType == 'day'"
              value-format="yyyy-MM-dd"
              v-model="dateRange"
              :picker-options="pickerOptions"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >{{ $t('button.search') }}</el-button
          >
          <el-button type="primary" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
          <el-button
            type="primary"
            icon="el-icon-upload2"
            @click="exportFile"
            v-if="$route.meta.authority.button.export"
            >{{ $t('button.export') }}</el-button
          >
          <!-- <el-button style='width:79px' @click="searchData();pageNum=1" :loading="loading">{{ $t('button.reset') }}</el-button>
            <el-button type="primary" icon="plus">{{ $t('button.addto') }}</el-button> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <!--<el-button type="text"-->
              <!--@click="download(scope.row)">下载订单详情</el-button>-->
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                @command="ctrlData($event, scope.$index, scope.row)"
              >
                <span class="el-dropdown-link">
                  下载账单明细<i class="el-icon-caret-bottom el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="differ">下载差异文件 </el-dropdown-item>
                  <el-dropdown-item command="wf"> 下载我方对账文件 </el-dropdown-item>
                  <el-dropdown-item command="df"> 下载对方对账文件 </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页器-->
      <div>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelNew, setIndex, dateFormat } from "@/common/js/public.js";
export default {
  name: "speciallyBusiness",
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setTime(date1.getTime() - 1000 * 60 * 60 * 24);
    let startTime = date1;
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setTime(date.getTime() - 1000 * 60 * 60 * 24);
    let endTime = date;
    return {
      index: 0,
      date: date1,
      dateRange: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > endTime;
        },
      },
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      berthList: [],
      payTypeList: [],
      roleList: [],
      date1: date1,
      startTime: startTime,
      endTime: endTime,
      // newCreateDate: payTime,
      tableCols: [
        {
          prop: "billDate",
          label: "支付日期",
          formatter: (row) => {
            if (row.billDate) {
              return row.billDate.substring(0, 10);
            }
          },
        },
        {
          prop: "sysMoney",
          label: "我方金额",
          formatter: (row) => {
            if (row.sysMoney) {
              return Number(row.sysMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "billMoney",
          label: "对方金额",
          formatter: (row) => {
            if (row.billMoney) {
              return Number(row.billMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "difMoney",
          label: "差额",
          formatter: (row) => {
            if (row.difMoney) {
              return Number(row.difMoney / 100).toFixed(2);
            } else {
              return "0.00";
            }
          },
        },
        {
          prop: "dealStatus",
          label: "平账进度",
          width: "",
          formatter: (row) => {
            if (row.dealStatus == 0) {
              return "未平账";
            } else if (row.dealStatus == 1) {
              return "已平账";
            } else {
              return "";
            }
          },
        },
      ],
      tableData: [],
      formInline: {
        operationId: "",
        // payTime: payTime,
        startTime: dateFormat(startTime, "yyyy-MM-dd"),
        endTime: dateFormat(endTime, "yyyy-MM-dd"),
        dateType: "day",
      },
    };
  },
  methods: {
    initDate() {
      let startDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      this.dateRange = [startDate, endDate];
    },
    resetForm() {
      // let date1 = new Date();
      // date1.setHours(0);
      // date1.setMinutes(0);
      // date1.setSeconds(0);
      // let startTime = date1;
      // let date = new Date();
      // date.setHours(23);
      // date.setMinutes(59);
      // date.setSeconds(59);
      // let endTime = date;
      // this.formInline = {
      //   dateType: 'day',
      //   billType: "2"
      // }
      // this.startTime = dateFormat(startTime, "yyyy-MM-dd")
      // this.endTime = dateFormat(endTime, "yyyy-MM-dd")
      let startDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      let endDate = this.$moment().subtract(1, "days").format("YYYY-MM-DD");
      this.dateRange = [startDate, endDate];
    },
    // 处理操作相关动作
    ctrlData(command, index, obj) {
      if (command == "wf") {
        this.downloadSys(obj);
      } else if (command == "df") {
        this.downloadChannel(obj);
      } else if (command == "differ") {
        this.downloadDif(obj);
      }
    },
    // 下载差异文件
    downloadDif(data) {
      exportExcelNew("/acb/2.0/bill/serviceProvider/wechat/diffDownload", {
        configPlatform: 1,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    // 下载我方对账文件
    downloadSys(data) {
      exportExcelNew("/acb/2.0/bill/serviceProvider/wechat/download", {
        configPlatform: 1,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    // 下载对方对账文件
    downloadChannel(data) {
      exportExcelNew("/acb/2.0/bill/serviceProvider/wechat/otherBilldownload", {
        configPlatform: 1,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    download(data) {
      let opt = {};
      opt.operationId = data.operationId;
      opt.payTime = this.formInline.payTime;
      exportExcelNew("/acb/2.0/operationRevenue/payOrderdownload", opt);
    },
    showLog() {
      if (this.startTime && this.endTime) {
        if (this.formInline.dateType == "day") {
          let startTime = dateFormat(new Date(this.startTime), "yyyy-MM-dd");
          let endTime = dateFormat(new Date(this.endTime), "yyyy-MM-dd");
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 31 * 24 * 60 * 60 * 1000;
          if (datadiff >= time) {
            this.$alert("搜索时间不能超过31天", "提示");
            return false;
          }
        } else if (this.formInline.dateType == "month") {
          let startTime = dateFormat(new Date(this.startTime), "yyyy-MM");
          let endTime = dateFormat(new Date(this.endTime), "yyyy-MM");
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 348 * 24 * 60 * 60 * 1000;
          if (datadiff >= time) {
            this.$alert("搜索时间不能超过12个月", "提示");
            return false;
          }
        } else if (this.formInline.dateType == "year") {
          let startTime = dateFormat(new Date(this.startTime), "yyyy");
          let endTime = dateFormat(new Date(this.endTime), "yyyy");
          this.formInline.startTime = startTime;
          this.formInline.endTime = endTime;
          let data1 = Date.parse(startTime.replace(/-/g, "/"));
          let data2 = Date.parse(endTime.replace(/-/g, "/"));
          let datadiff = data2 - data1;
          let time = 348 * 2 * 24 * 60 * 60 * 1000;
          if (datadiff >= time) {
            this.$alert("搜索时间不能超过2年", "提示");
            return false;
          }
        }
        if (this.formInline.startTime > this.formInline.endTime) {
          // eslint-disable-next-line no-alert
          this.$alert("结束时间不能小于开始时间");
          return false;
        } else {
          return true;
        }
      } else {
        return true;
        //		          this.$alert('日期不能为空', this.$t('pop_up.Tips'), {
        //		            confirmButtonText: this.$t('pop_up.Determine')
        //		          });
        //		          return false
      }
    },
    // showLog () {
    //   if (this.date) {
    //     let payTime = dateFormat(this.date, 'yyyy-MM-dd');
    //     this.formInline.payTime = payTime || this.date;
    //   }
    // },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios.get("/acb/2.0/bill/tenant/tenantList").then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.roleList = res.value;
          this.searchData();
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 获取支付方式
    getPay() {},
    searchData() {
      let startTime = "";
      let endTime = "";
      if (!this.dateRange || this.dateRange == "") {
        this.$alert("日期不能为空！");
        return;
      } else {
        startTime = this.dateRange[0];
        endTime = this.dateRange[1];
      }
      this.formInline.startTime = startTime;
      this.formInline.endTime = endTime;
      this.$axios
        .get("/acb/2.0/bill/serviceProvider/wechat/list", {
          data: {
            page: this.pageNum,
            pageSize: this.pageSize,
            dateType: this.formInline.dateType,
            tenantId: this.formInline.operationId,
            configPlatform: 1,
            startTime: this.formInline.startTime,
            endTime: this.formInline.endTime,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = setIndex(this.pageNum, res.value.list);
            this.newCreateDate = this.formInline.payTime;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // // 下载差异文件
    // downloadDif () {
    //   exportExcelNew('/gplus/bill/download/dif', {
    //     'billType': this.formInline.billType,
    //     'billDate': this.formInline.billDate
    //   });
    // },
    // 导出
    exportFile() {
      exportExcelNew("/acb/2.0/financeStatistic/report/export", {
        dateType: "day",
        startTime: this.formInline.payTime,
        endTime: this.formInline.payTime,
      });
    },
  },
  components: {},
  mounted() {
    this.getPay();
    this.getRoleList();
    this.initDate();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
